import React from "react";
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import CookingServicesBreadcrumb from "./ServicesBreadcrumb/CookingServicesBreadcrumb";
import Form from "./../Services/Form";

const CookingRangeServices = () => {
  return (
    <>
      <Navigation />
      <CookingServicesBreadcrumb />
      <section className="tp-about-area position-relative pt-120 pb-60 fix">
        <div className="container">
          <div className="row justify-content-xl-between justify-content-md-center">
            <div className="col-xl-6">
              <div className="tp-service-details-img mb-30">
                <img
                  src="assets/img/service/cookingrangea.png"
                  alt="img not found"
                  height="440px"
                />
              </div>
            </div>
            {/* </div> */}
            <div className="col-xl-6 col-md-10 pl-30">
              <div
                className="tp-about-text z-index wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="section-title-wrapper mb-30 pt-10">
                  {/* <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                    About Hob Services
                  </h5> */}
                  <h2 className="tp-section-title">
                    About Cooking Range Service{" "}
                  </h2>
                </div>
                <p className="mb-30 pt-10">
                  Cooking range service involves a series of maintenance and
                  repair tasks aimed at ensuring the proper functioning,
                  efficiency, and safety of cooking ranges. A cooking range is a
                  combination of a stove (hob) and an oven Cooking range service
                  is essential for maintaining the overall functionality and
                  safety of your kitchen appliance. It improves cooking results,
                  energy efficiency, and the lifespan of the equipment, so reach
                  out to classic services for the best result at a reasonable
                  price.
                </p>
                <div className="col-xl-6 col-lg-3 ">
                  <div
                    className="tp-appoint text-end wow fadeInUp"
                    data-wow-delay=".9s"
                  >
                    <div class="button-container">
                      <button class="custom-button">
                        <a href="tel:+91 6362503661">
                          <i class="icon">
                            <FaPhoneAlt />
                          </i>
                          Call Us
                        </a>
                      </button>
                      <button class="custom-button2">
                        <a href="https://wa.link/al1chv">
                          <i class="icon1">
                            <FaWhatsapp />
                          </i>
                          WhatsApp Us
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="tp-service-details">
            <div className="text-center">
              <h2 className="tp-section-title heading-color-black pt-40 mb-20">
                Comprehensive Cooking Range Services
              </h2>
            </div>
            <p className="mb-50">
              <b>Crafting Excellence in Your Culinary Haven:</b>
              <br />
              Welcome to Classic Repair, your trusted partner for expert cooking
              range service, repair, and cleaning. We understand that your
              cooking range is the centrepiece of your kitchen, where culinary
              dreams come to life. With an unwavering commitment to precision,
              safety, and efficiency, our comprehensive range of services
              ensures that your cooking range is always poised to create
              exceptional dishes and cherished memories.
              <br />
              <br />
              <b>Cooking Range Inspection and Repair:</b>
              <br /> Our skilled technicians diagnose and rectify a wide
              spectrum of cooking range issues. From burner malfunctions to
              faulty controls, we employ advanced techniques to restore your
              cooking range's performance. Thorough inspections help identify
              potential concerns before they disrupt your cooking experience.
              <br />
              <br />
              <b>Cooking Range Cleaning and Maintenance:</b>
              <br /> Elevate your cooking environment with our professional
              cooking range cleaning services. Our meticulous process eliminates
              stubborn grease, spills, and residue that can impact your cooking
              and aesthetics. Regular cleaning enhances the appearance of your
              cooking range and ensures even heating and prolonged durability.
              <br />
              <br />
              <b>Burner Restoration:</b>
              <br /> A burner in need is a culinary opportunity missed. Our
              experts excel in restoring or replacing burners, ensuring
              consistent and precise heat distribution for a seamless cooking
              experience.
              <br />
              <br />
              <b>Control Panel and Knob Repair: </b>
              <br />
              Our skilled technicians are here to assist if your cooking range's
              control panel or knobs are malfunctioning. We diagnose and repair
              issues related to controls, timers, and temperature settings,
              ensuring your cooking range is user-friendly and reliable.
              <br />
              <br />
              <b>Gas Line Inspection and Repair:</b>
              <br /> Safety is paramount. Our professionals conduct thorough gas
              line inspections, promptly identifying and repairing any leaks or
              issues to ensure your cooking environment remains secure for you
              and your loved ones.
              <br />
              <br />
              <b>Energy Efficiency Enhancement:</b>
              <br /> We're committed to responsible energy use. Our energy
              efficiency enhancement services identify opportunities to reduce
              energy wastage and offer solutions to enhance insulation and
              overall efficiency, saving energy and costs. At Classic Repair,
              we're dedicated to enhancing your culinary prowess. Our devoted
              team combines expertise, state-of-the-art technology, and a
              customer-centric approach to provide you with a cooking range
              that's dependable, efficient, and immaculately clean. Trust us to
              be your culinary partner, ensuring your cooking range remains the
              heart of delectable meals and cherished moments.
            </p>
            <Form />
            <div className="row">
              <div className="col-sm-6">
                <div className="tp-service-details-img mb-10">
                  <img
                    src="assets/img/service/cookingrange1.png"
                    alt="img not found"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="tp-service-details-img mb-10">
                  <img
                    src="assets/img/service/cookingrange2.png"
                    alt="img not found"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CookingRangeServices;
