import React from "react";
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import OvenServicesBreadcrumb from "./ServicesBreadcrumb/OvenServicesBreadcrumb";
import Form from "./../Services/Form";

const OvenServices = () => {
  return (
    <>
      <Navigation />
      <OvenServicesBreadcrumb />
      <section className="tp-about-area position-relative pt-120 pb-60 fix">
        <div className="container">
          <div className="row justify-content-xl-between justify-content-md-center">
            <div className="col-xl-6">
              <div className="tp-service-details-img mb-30">
                <img
                  src="assets/img/service/oven.png"
                  alt="img not found"
                  height="450px"
                />
              </div>
            </div>
            {/* </div> */}
            <div className="col-xl-6 col-md-10 pl-30">
              <div
                className="tp-about-text z-index wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="section-title-wrapper mb-30 pt-10">
                  {/* <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                    About Hob Services
                  </h5> */}
                  <h2 className="tp-section-title">About Oven Service </h2>
                </div>
                <p className="mb-30 pt-10">
                  Oven services typically involve a range of maintenance and
                  repair tasks aimed at ensuring the proper functioning,
                  efficiency, and safety of ovens. Ovens are essential kitchen
                  appliances used for baking, roasting, and cooking various
                  dishes. Over time, ovens can develop issues that affect their
                  performance Addressing these concerns, our company, Classic
                  Services, emerges as the optimal choice. trusted by a
                  multitude of satisfied customers. Our oven repair service in
                  Bangalore sets the standard for excellence, all while keeping
                  our prices competitive.
                </p>
                <div className="col-xl-6 col-lg-3 ">
                  <div
                    className="tp-appoint text-end wow fadeInUp"
                    data-wow-delay=".9s"
                  >
                    <div class="button-container">
                      <button class="custom-button">
                        <a href="tel:+91 6362503661">
                          <i class="icon">
                            <FaPhoneAlt />
                          </i>
                          Call Us
                        </a>
                      </button>
                      <button class="custom-button2">
                        <a href="https://wa.link/al1chv">
                          <i class="icon1">
                            <FaWhatsapp />
                          </i>
                          WhatsApp Us
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="tp-service-details">
            <div className="text-center">
              <h2 className="tp-section-title heading-color-black pt-40 mb-20">
                Comprehensive Oven Care Services
              </h2>
            </div>
            <p className="mb-50">
              <b>Your Recipe for Oven Excellence:</b>
              <br />
              Welcome to Classic Repair, your premier destination for expert
              oven service, repair, and cleaning. We understand that your oven
              is the heart of your kitchen, and its optimal performance is
              essential for creating culinary masterpieces. With a commitment to
              precision, safety, and efficiency, our comprehensive range of
              services ensures that your oven is always ready to deliver
              exceptional results.
              <br />
              <br />
              <b>Oven Inspection and Repair:</b>
              <br /> Our skilled technicians diagnose and rectify various oven
              issues. From temperature inconsistencies to faulty components, we
              employ advanced techniques to restore your oven's functionality.
              Thorough inspections help identify potential problems before they
              escalate, ensuring uninterrupted cooking experiences.
              <br />
              <br />
              <b>Oven Cleaning and Maintenance:</b>
              <br /> Experience a cleaner, more hygienic cooking environment
              with our professional oven cleaning services. Our meticulous
              process eliminates stubborn grease, food residue, and grime that
              can affect taste and efficiency. Regular cleaning not only
              enhances the aesthetics of your oven but also promotes even
              cooking and extends its lifespan.
              <br />
              <br />
              <b>Heating Element Replacement:</b>
              <br /> A malfunctioning heating element can disrupt your culinary
              adventures. Our experts specialize in replacing heating elements,
              ensuring your oven reaches and maintains the desired temperature
              accurately, enabling you to create perfectly cooked dishes every
              time.
              <br />
              <br />
              <b>Control Panel and Sensor Repair:</b>
              <br /> If your oven's control panel or sensors are acting up, our
              skilled technicians are here to help. We diagnose and repair
              issues related to controls, timers, and temperature sensors,
              ensuring precise and reliable oven performance.
              <br />
              <br />
              <b>Door Seal Restoration:</b>
              <br /> A compromised door seal can lead to heat loss and uneven
              cooking. Our professionals are equipped to restore or replace door
              seals, maintaining a secure seal that traps heat effectively and
              prevents energy wastage.
              <br />
              <br />
              <b>Energy Efficiency Enhancement:</b>
              <br /> Save on energy costs with our energy efficiency enhancement
              services. We identify areas where heat loss occurs and offer
              solutions to improve insulation and overall efficiency, making
              your oven more eco-friendly and cost-effective. At Classic Repair,
              we're passionate about elevating your cooking experience. Our
              dedicated team combines expertise, state-of-the-art technology,
              and a customer-centric approach to provide a reliable, efficient,
              and immaculately clean oven. Trust us to be your culinary partner,
              ensuring your oven remains the centrepiece of delectable meals and
              delightful memories.
            </p>
            <Form />
            <div className="row">
              <div className="col-sm-6">
                <div className="tp-service-details-img mb-10">
                  <img src="assets/img/service/oven1.png" alt="img not found" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="tp-service-details-img mb-10">
                  <img src="assets/img/service/oven2.png" alt="img not found" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default OvenServices;
