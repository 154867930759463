import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

const FaberServicesBreadcrumb = () => {
    return (
        <>
            <Breadcrumb title="Faber" subTitle="Services" smText='Services'></Breadcrumb>
        </>
    );
};

export default FaberServicesBreadcrumb;