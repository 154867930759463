import React from "react";
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";

const AboutArea = () => {
  return (
    <>
      <section className="tp-about-area position-relative pt-120 pb-60 fix">
        {/* <div className="tp-about-shape">
          <img
            src="assets/img/about/about-shape-1.jpg"
            className="img-fluid"
            alt="img not found"
          />
        </div> */}
        <div className="container">
          <div className="row justify-content-xl-between justify-content-md-center">
            <div className="col-xl-5 col-12">
              <div
                className="tp-about-img z-index wow fadeInUp"
                data-wow-delay=".3s"
              >
                <img src="assets/img/about/b.png" alt="img not found" />
              </div>
            </div>
            <div className="col-xl-6 col-md-10">
              <div
                className="tp-about-text z-index wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="section-title-wrapper mb-30">
                  <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                    About our Company
                  </h5>
                  <h2 className="tp-section-title">
                    Your One-Stop Solution for Kitchen Appliance Care!{" "}
                  </h2>
                </div>
                <p className="mb-40">
                  Discover the ultimate convenience in kitchen appliance care
                  with Classic Repair. Our all-in-one solution encompasses
                  various services to rejuvenate your home cooking experience.
                  Our Oven Cleaning Service meticulously banishes grime, leaving
                  your oven sparkling and efficient. With our Chimney Service,
                  ensure a safer environment as we expertly inspect, clean, and
                  repair your chimney, preventing potential hazards. Experience
                  seamless cooking again with our Hob Service and Repair,
                  covering all types of hobs to diagnose issues and provide
                  effective solutions swiftly. At Classic Repair, we're your
                  dedicated partner in elevating your kitchen's safety,
                  efficiency, and overall charm.
                </p>
                <div className="col-xl-7 col-lg-3 ">
                  <div
                    className="tp-appoint text-end wow fadeInUp"
                    data-wow-delay=".9s"
                  >
                    <div class="button-container">
                            <button class="custom-button">
                                <a href="tel:+91 6362503661">
                                    <i class="icon">
                                        <FaPhoneAlt />
                                    </i>
                                    Call Us
                                </a>
                            </button>
                            <button class="custom-button1">
                                <a href="https://wa.link/al1chv">
                                    <i class="icon1">
                                        <FaWhatsapp />
                                    </i>
                                    WhatsApp Us
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
                {/* <div className="row mb-10"> */}
                {/* <div className="col-sm-6">
                    <div className="tp-about-service mb-30">
                      <div className="tp-about-service-icon yellow-circle-shape mb-15">
                        <i className="flaticon-snowfall"></i>
                      </div>
                      <div className="tp-about-service-text">
                        <h4 className="tp-about-service-text-title mb-15 hover-theme-color">
                          <Link to="/services">
                            Residential Cleaning Services
                          </Link>
                        </h4>
                        <p>
                          Duis congue atend lorem consequat interdum pretium
                          ligula semper
                        </p>
                      </div>
                    </div>
                  </div> */}
                {/* <div className="col-sm-6"> */}
                {/* <div className="tp-about-service mb-30">
                      <div className="tp-about-service-icon yellow-circle-shape mb-15">
                        <i className="flaticon-business-and-trade"></i>
                      </div>
                      <div className="tp-about-service-text">
                        <h4 className="tp-about-service-text-title mb-15 hover-theme-color">
                          <Link to="/services">
                            Commercial Cleaning Services
                          </Link>
                        </h4>
                        <p>
                          Duis congue atend lorem consequat interdum pretium
                          ligula semper
                        </p>
                      </div>
                    </div> */}
              </div>
            </div>
            {/* <div className="row"> */}
            {/* <div className="col-sm-6">
                    <div className="tp-about-author mb-30">
                      <div className="tp-about-author-img">
                        <img
                          src="assets/img/about/about-author.png"
                          className="img-fluid"
                          alt="img not found"
                        />
                      </div>
                      <div className="tp-about-author-text">
                        <h4 className="tp-about-author-text-title">
                          Daniel Martyn
                        </h4>
                        <span>CEO - Fetoxe</span>
                      </div>
                    </div>
                  </div> */}
            {/* <div className="col-sm-6">
                    <div className="tp-about-number mb-30">
                      <div className="tp-about-number-icon">
                        <i className="flaticon-phone-call-1"></i>
                      </div>
                      <div className="tp-about-number-text">
                        <span>Free Consutacy</span>
                        <a href="tel:02(552)6620808">02 (552) 662 0808</a>
                      </div>
                    </div>
                  </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutArea;
