import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelopeOpen } from "react-icons/fa";

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_kicl8yc",
        "template_aphe1ki",
        form.current,
        "oIlBKT1UMfyW1vJKj"
      )
      .then(
        (result) => {
          console.log(result.text);
          window.location.href = window.location.origin + "/Thankyou";
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <section className="tp-contact-area pb-50 pt-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div
                  className="section-title-wrapper-two mb-50 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black">
                    Your Just One Click Away{" "}
                  </h5>
                  <h2 className="tp-section-title heading-color-black">
                    If you Have Any Query, Don’t Hesitate <br />
                    Contact with us{" "}
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="row custom-mar-20">
                  <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                    <div
                      className="tp-contact-info mb-40 wow fadeInUp"
                      data-wow-delay=".4s"
                    >
                      <div className="tp-contact-info-icon">
                        <i>
                          {" "}
                          <FaMapMarkerAlt className="contact_icon" />{" "}
                        </i>
                      </div>
                      <div className="tp-contact-info-text">
                        <h4 className="tp-contact-info-title mb-15">Address</h4>
                        <p>
                          Classic Services No1, 7th C Main Rd, Bandappa Garden,
                          Mathikere, Bengaluru, Karnataka 560054
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                    <div
                      className="tp-contact-info mb-40 wow fadeInUp"
                      data-wow-delay=".6s"
                    >
                      <div className="tp-contact-info-icon">
                        <i>
                          {" "}
                          <FaPhoneAlt className="contact_icon" />{" "}
                        </i>
                      </div>
                      <div className="tp-contact-info-text">
                        <h4 className="tp-contact-info-title mb-15">Phone</h4>
                        <a href="tel:+91 6362503661">+91 6362503661</a>
                        {/* <a href="tel:444555300-25">444555300-25</a> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                    <div
                      className="tp-contact-info mb-40 wow fadeInUp"
                      data-wow-delay=".8s"
                    >
                      <div className="tp-contact-info-icon">
                        <i>
                          {" "}
                          <FaEnvelopeOpen className="contact_icon" />{" "}
                        </i>
                      </div>
                      <div className="tp-contact-info-text">
                        <h4 className="tp-contact-info-title mb-15">Email</h4>
                        <a href="mailto:classicserviceIndia@gmail.com">
                          classicserviceIndia@gmail.com
                        </a>
                        {/* <a href="mailto:info@themepure.com">info@themepure.com</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="tp-contact-form">
                  <div className="row custom-mar-20">
                    <div className="col-md-6 custom-pad-20">
                      <div className="tp-contact-form-field mb-20">
                        <input
                          type="text"
                          placeholder="Full name"
                          name="from_name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 custom-pad-20">
                      <div className="tp-contact-form-field mb-20">
                        <input
                          type="email"
                          placeholder="Email Address"
                          name="from_email"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 custom-pad-20">
                      <div className="tp-contact-form-field mb-20">
                        <input type="tel" placeholder="Phone" name="Phone" />
                      </div>
                    </div>
                    <div className="col-md-6 custom-pad-20">
                      <div className="tp-contact-form-field select-field-arrow mb-20">
                        <select name="service">
                          <option defaultValue="">Select Services</option>
                          <option value="Chimney Services">
                            Chimney Services
                          </option>
                          <option value="Hob Services">Hob Services</option>
                          <option value="Oven Services">Oven Services</option>
                          <option value="Faber Services">Faber Services</option>
                          <option value="Elica Services">Elica Services</option>
                          <option value="Gilma Services">Gilma Services</option>
                          <option value="Kaff Services">Kaff Services</option>
                          <option value="Cooking Range Services">
                            Cooking Range Services
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12 custom-pad-20">
                      <div className="tp-contact-form-field mb-20">
                        <textarea
                          placeholder="Your Message"
                          name="message"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12 custom-pad-20">
                      <div className="tp-contact-form-field">
                        <button
                          input
                          type="submit"
                          value="Send"
                          className="theme-btn text-white"
                        >
                          <i className="flaticon-enter"></i> Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  );
};

export default ContactForm;
