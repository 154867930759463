import React from "react";
import { Link } from "react-router-dom";

const WorkingProcess = () => {
  return (
    <>
      <section className="tp-feature-area-three pt-70 pb-70 bg-gray-light">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="section-title-wrapper text-center mb-45 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-50 heading-color-black">
                  Our Working Process
                </h5>
                {/* <h2 className="tp-section-title mb-25 heading-color-black">
                  Excellent Techniques For <br />
                  Effective Cleaning
                </h2> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-sm-6">
              <div
                className="tp-feature-three text-center mb-30 wow fadeInUp"
                data-wow-delay=".4s"
              >
                {/* <div className="tp-feature-three-icon mb-30">
                  <img
                    src="assets/img/icon/fact-bg.png"
                    className="img-fluid"
                    alt="img not found"
                  />
                  <i className="flaticon-booking"></i>
                </div> */}
                <div className="tp-feature-three-text">
                  <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">
                    Your Appliance Care Experts
                  </h4>
                  <p class="justified-text">
                    At Classic Repair, we are your dedicated partner in
                    providing top-tier appliance care services that transform
                    your kitchen into a haven of efficiency and safety. With a
                    focus on Oven Cleaning, Chimney Service, and Hob Service
                    with Repair.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div
                className="tp-feature-three text-center mb-30 wow fadeInUp"
                data-wow-delay=".7s"
              >
                {/* <div className="tp-feature-three-icon mb-30">
                  <img
                    src="assets/img/icon/feature-bg.png"
                    className="img-fluid"
                    alt="img not found"
                  />
                  <i className="flaticon-delivery-box"></i>
                </div> */}
                <div className="tp-feature-three-text">
                  <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">
                    Expertise and Dedication
                  </h4>
                  <p class="justified-text">
                    With years of experience, our team of skilled technicians
                    specialize in Oven Cleaning, Chimney Service, and Hob
                    Service with Repair. We understand that these vital
                    components are the heart of your kitchen, and their proper
                    functioning is paramount.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div
                className="tp-feature-three text-center mb-30 wow fadeInUp"
                data-wow-delay="1s"
              >
                {/* <div className="tp-feature-three-icon mb-30">
                  <img
                    src="assets/img/icon/feature-bg.png"
                    className="img-fluid"
                    alt="img not found"
                  />
                  <i className="flaticon-boy"></i>
                </div> */}
                <div className="tp-feature-three-text">
                  <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">
                    Safety and Satisfaction
                  </h4>
                  <p class="justified-text">
                    Your safety and satisfaction are our cornerstones. Our
                    Chimney Service clears potential hazards and improves air
                    quality, creating a healthier home. Our Hob Service repairs
                    and optimizes your cooking appliance for precision and ease.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-3 col-sm-6">
                     <div className="tp-feature-three text-center mb-30 wow fadeInUp" data-wow-delay="1.3s">
                        <div className="tp-feature-three-icon mb-30">
                           <img src="assets/img/icon/feature-bg.png" className="img-fluid" alt="img not found"/>
                              <i className="flaticon-cleaning"></i>
                        </div>
                        <div className="tp-feature-three-text">
                           <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">
                              <Link to="/projectsDetails">Start Cleaning</Link></h4>
                           <p>Coingue aten lorem consqua interdum pretium ligaula a semper mauris easy dictuma</p>
                        </div>
                     </div>
                  </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkingProcess;
