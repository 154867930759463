import React from "react";
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import HobServicesBreadcrumb from "./ServicesBreadcrumb/HobServicesBreadcrumb";
import Form from "./../Services/Form";

const HobServices = () => {
  return (
    <>
      <Navigation />
      <HobServicesBreadcrumb />
      <section className="tp-about-area position-relative pt-120 pb-60 fix">
        <div className="container">
          <div className="row justify-content-xl-between justify-content-md-center">
            <div className="col-xl-6">
              <div className="tp-service-details-img mb-30">
                <img
                  src="assets/img/service/hob.png"
                  alt="img not found"
                  height="450px"
                />
              </div>
            </div>
            {/* </div> */}
            <div className="col-xl-6 col-md-10 pl-30">
              <div
                className="tp-about-text z-index wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="section-title-wrapper mb-30 pt-10">
                  {/* <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                    About Hob Services
                  </h5> */}
                  <h2 className="tp-section-title">About Hob Service </h2>
                </div>
                <p className="mb-30 pt-10">
                  Stove hob service involves a range of activities aimed at
                  ensuring the proper functioning, efficiency, and safety of the
                  hobs on a stove or cooktop. The hob is the flat cooking
                  surface where you place your pots and pans to prepare meals.
                  Over time, hobs can experience wear and tear, as well as
                  functional issues. Addressing these concerns, our company,
                  Classic Services, emerges as the optimal choice. Thousands of
                  customers rely on us for top-quality gas hob service. We offer
                  the best kitchen hob repair service in Bangalore at a
                  competitive price.
                </p>
                <div className="col-xl-6 col-lg-3 ">
                  <div
                    className="tp-appoint text-end wow fadeInUp"
                    data-wow-delay=".9s"
                  >
                    <div class="button-container">
                      <button class="custom-button">
                        <a href="tel:+91 6362503661">
                          <i class="icon">
                            <FaPhoneAlt />
                          </i>
                          Call Us
                        </a>
                      </button>
                      <button class="custom-button2">
                        <a href="https://wa.link/al1chv">
                          <i class="icon1">
                            <FaWhatsapp />
                          </i>
                          WhatsApp Us
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="tp-service-details">
            <div className="text-center">
              <h2 className="tp-section-title heading-color-black pt-40 mb-20">
                Comprehensive Hob Care Services
              </h2>
            </div>
            <p className="mb-50">
              <b>Igniting Excellence in Your Culinary Journey:</b>
              <br />
              Welcome to Classic Repair, your trusted destination for expert hob
              service, repair, and cleaning. We understand that your hob is the
              foundation of your kitchen, where culinary magic unfolds. With a
              commitment to precision, safety, and efficiency, our comprehensive
              range of services ensures that your hob is always ready to create
              exceptional meals and memories.
              <br />
              <br />
              <b>Hob Inspection and Repair:</b>
              <br /> Our skilled technicians diagnose and resolve various hob
              issues. From uneven heating to faulty ignition systems, we employ
              advanced techniques to restore your hob's performance. Thorough
              inspections help identify potential concerns before they impact
              your cooking experience.
              <br />
              <br />
              <b>Hob Cleaning and Maintenance:</b>
              <br /> Elevate your cooking environment with our professional hob
              cleaning services. Our meticulous process removes stubborn grease,
              spills, and residue that can affect your cooking and aesthetics.
              Regular cleaning not only enhances the appearance of your hob but
              also ensures even heating and prolonged durability.
              <br />
              <br />
              <b>Ignition System Restoration:</b>
              <br /> A malfunctioning ignition system can hinder your culinary
              adventures. Our experts excel in restoring or replacing ignition
              systems, ensuring quick and reliable ignition, and enabling you to
              start cooking confidently.
              <br />
              <br />
              <b>Temperature Control Calibration:</b>
              <br /> Precise temperature control is crucial for cooking
              perfection. If your hob's temperature settings are inaccurate, our
              technicians recalibrate them, guaranteeing consistent and accurate
              heating levels for a flawless cooking experience.
              <br />
              <br />
              <b>Gas Leak Inspection and Repair:</b>
              <br /> Safety is our top priority. Our professionals conduct
              thorough gas leak inspections, swiftly identifying and repairing
              any leaks to ensure your cooking environment remains secure for
              you and your loved ones.
              <br />
              <br />
              <b>Energy Efficiency Enhancement:</b>
              <br /> We're dedicated to reducing energy wastage. Our energy
              efficiency enhancement services pinpoint areas of heat loss and
              offer solutions to improve insulation and overall efficiency,
              saving energy and money. At Classic Repair, we're passionate about
              enhancing your culinary endeavours. Our dedicated team combines
              expertise, cutting-edge technology, and a customer-focused
              approach to provide you with a reliable, efficient, and impeccably
              clean hob. Trust us to be your culinary companion, ensuring your
              hob remains the centrepiece of delicious meals and unforgettable
              moments.
            </p>
            <Form />
            <div className="row">
              <div className="col-sm-6">
                <div className="tp-service-details-img mb-10">
                  <img src="assets/img/service/hob1.png" alt="img not found" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="tp-service-details-img mb-10">
                  <img src="assets/img/service/hob2.png" alt="img not found" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default HobServices;
