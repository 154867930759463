import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

const ChimneyServicesBreadcrumb = () => {
    return (
        <>
            <Breadcrumb title="Chimney" subTitle="Service" smText='Service'></Breadcrumb>
        </>
    );
};

export default ChimneyServicesBreadcrumb;