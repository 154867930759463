import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

const CtaArea = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_kicl8yc",
        "template_hrjd09a",
        form.current,
        "oIlBKT1UMfyW1vJKj"
      )
      .then(
        (result) => {
          console.log(result.text);
          window.location.href = window.location.origin + "/Thankyou";
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <section className="tp-appoint-cta-area yellow-dark-bg pt-80 pb-65">
          <div className="container">
            <div className="row align-items-center custom-mar-20">
              <div className="col-xl-2 col-lg-12 custom-pad-20">
                <div className="tp-appoint wow fadeInUp" data-wow-delay=".1s">
                  <h4 className="tp-appoint-title">Enquire Now!!! </h4>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9 custom-pad-20">
                <div className="row align-items-center custom-mar-20">
                  <div className="col-lg-4 custom-pad-20">
                    <div
                      className="tp-appoint wow fadeInUp"
                      data-wow-delay=".3s"
                    >
                      {/* <input type="text" placeholder="Full Name" /> */}
                      <input
                        type="text"
                        name="from_name"
                        placeholder="Full Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 custom-pad-20">
                    <div
                      className="tp-appoint wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      {/* <input type="text" placeholder="Phone Number" /> */}
                      <input
                        type="email"
                        name="from_email"
                        placeholder="Email Address"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 custom-pad-20">
                    <div
                      className="tp-appoint select-field-arrow wow fadeInUp"
                      data-wow-delay=".7s"
                    >
                      <select name="service">
                        <option defaultValue="">Select Services</option>
                        <option value="Chimney Services">
                          Chimney Services
                        </option>
                        <option value="Hob Services">Hob Services</option>
                        <option value="Oven Services">Oven Services</option>
                        <option value="Faber Services">Faber Services</option>
                        <option value="Elica Services">Elica Services</option>
                        <option value="Gilma Services">Gilma Services</option>
                        <option value="Kaff Services">Kaff Services</option>
                        <option value="Cooking Range Services">
                          Cooking Range Services
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 custom-pad-20">
                <div
                  className="tp-appoint text-end wow fadeInUp"
                  data-wow-delay=".9s"
                >
                  <button
                    input
                    type="submit"
                    value="Send"
                    className="theme-btn text-white"
                  >
                    <i className="flaticon-enter"></i> Submit Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  );
};

export default CtaArea;
