import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home/Home";
import About from "./pages/About/About/About";
import Services from "./pages/Services/Services/Services";
import Contact from "./pages/Contact/Contact/Contact";
import { WOW } from "wowjs";
import ScrollToTop from "./components/ScrollToTop";
import HobServices from "./pages/Services/Services/HobServices";
import OvenServices from "./pages/Services/Services/OvenServices";
import ChimneyServices from "./pages/Services/Services/ChimneyServices";
import CookingRangeServices from "./pages/Services/Services/CookingRangeServices";
import FaberServices from "./pages/Services/Services/FaberServices";
import ElicaServices from "./pages/Services/Services/ElicaServices";
import GilmaServices from "./pages/Services/Services/GilmaServices";
import KaffServices from "./pages/Services/Services/KaffServices";
import Thankyou from "./Thankyou";

const App = () => {
  // wow animation active
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/HobServices" element={<HobServices />} />
          <Route path="/FaberServices" element={<FaberServices />} />
          <Route path="/ElicaServices" element={<ElicaServices />} />
          <Route path="/OvenServices" element={<OvenServices />} />
          <Route path="/ChimneyServices" element={<ChimneyServices />} />
          <Route
            path="/CookingRangeServices"
            element={<CookingRangeServices />}
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/GilmaServices" element={<GilmaServices />} />
          <Route path="/KaffServices" element={<KaffServices />} />
          <Route path="/Thankyou" element={<Thankyou />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
