import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

const ElicaServicesBreadcrumb = () => {
    return (
        <>
            <Breadcrumb title="Elica" subTitle="Services" smText='Services'></Breadcrumb>
        </>
    );
};

export default ElicaServicesBreadcrumb;