import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";

const ServicePageArea3 = ({
  service_image_num,
  ser_icon_img,
  ser_title,
  colorClass,
}) => {
  return (
    <>
      <div className="col-lg-6">
        <div
          className="tp-service-three tp-service-four mb-30 wow fadeInUp"
          data-wow-delay="1.2s"
        >
          <div className="tp-service-three-img">
            <img
              src={`assets/img/service/service-${service_image_num}.png`}
              className="img-fluid"
              alt="img-not-found"
            />
            {/* <div className="tp-service-three-img-icon">
                        <i className={`flaticon-${ser_icon_img}`}></i>
                     </div> */}
            {/* <div className="tp-service-three-img"> */}
            {/* <div className="tp-service-three-img-overlay-icon">
                          <i className={`flaticon-${ser_icon_img}`}></i>
                        </div> */}
            {/* </div> */}
          </div>
          <div className="tp-service-three-text fix">
            <h4
              className={
                colorClass
                  ? "home_three_title tp-service-three-title mb-20 heading-color-black-with-hover"
                  : "tp-service-three-title mb-20 heading-color-black-with-hover"
              }
            >
              <Link to="/CookingRangeServices">{ser_title}</Link>
            </h4>
            <p className="mb-30">
              Cooking range service involves a series of maintenance and repair
              tasks aimed at ensuring proper functioning, efficiency, and
              safety of cooking ranges......
            </p>
            {/* <div className="tp-service-three-text-btn">
                     <Link to="/servicesDetails" className="yellow-btn service__btn">
                        <i className="flaticon-enter"></i> Call Us</Link>
                        <Link to="/servicesDetails" className="yellow-btn service__btn">
                        <i className="flaticon-enter"></i> Whatsapp Us</Link>
                  </div> */}
            <div class="button-container">
              <a href="tel:+91 6362503661">
                <button class="custom-button">
                  <i class="icon">
                    <FaPhoneAlt />
                  </i>
                  Call Us
                </button>
              </a>
              <button class="custom-button1">
                <a href="https://wa.link/al1chv">
                  <i class="icon1">
                    <FaWhatsapp />
                  </i>
                  WhatsApp Us
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePageArea3;
