import React from "react";
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import GilmaServicesBreadcrumb from "./ServicesBreadcrumb/GilmaServicesBreadcrumb";
import Form from "./../Services/Form";

const GilmaServices = () => {
  return (
    <>
      <Navigation />
      {/* <ApointArea /> */}
      <GilmaServicesBreadcrumb />

      <section className="tp-about-area position-relative pt-60 pb-60 fix">
        <div className="container">
          <div className="row justify-content-xl-between justify-content-md-center">
            {/* </div> */}
            <div className="col-xl-6 col-md-10 pl-30">
              <div
                className="tp-about-text z-index wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="section-title-wrapper mb-30 pt-10">
                  {/* <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                    About Hob Services
                  </h5> */}
                  <h2 className="tp-section-title">Gilma Chimney Service </h2>
                </div>
                <p className="mb-30 pt-10">
                  Classic Service specializes in delivering high-quality service
                  for Gilma chimneys. We offer comprehensive services, including
                  Gilma chimney servicing, repairs, and maintenance. Whether you
                  require routine servicing to ensure your Gilma chimney
                  continues to function efficiently, expert repairs to address
                  any issues, or thorough care to optimize its performance, our
                  skilled technicians are here to assist you. We take pride in
                  providing efficient and reliable solutions to keep your Gilma
                  chimney in excellent working condition, ensuring your kitchen
                  remains clean and smoke-free.
                </p>

                <div className="col-xl-6 col-lg-3 ">
                  <div
                    className="tp-appoint text-end wow fadeInUp"
                    data-wow-delay=".9s"
                  >
                    <div class="button-container">
                      <button class="custom-button">
                        <a href="tel:+91 6362503661">
                          <i class="icon">
                            <FaPhoneAlt />
                          </i>
                          Call Us
                        </a>
                      </button>
                      <button class="custom-button2">
                        <a href="https://wa.link/al1chv">
                          <i class="icon1">
                            <FaWhatsapp />
                          </i>
                          WhatsApp Us
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="tp-service-details-img mb-30">
                <img
                  src="assets/img/service/Gilma Chimney.png"
                  alt="img not found"
                  //   height="450px"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tp-about-area position-relative pt-60 pb-60 fix">
        <div className="container">
          <div className="row justify-content-xl-between justify-content-md-center">
            <div className="col-xl-5">
              <div className="tp-service-details-img mb-30">
                <img
                  src="assets/img/service/Gilma Hob.png"
                  alt="img not found"
                  //   height="450px"
                />
              </div>
            </div>
            {/* </div> */}
            <div className="col-xl-6 col-md-10 pl-30">
              <div
                className="tp-about-text z-index wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="section-title-wrapper mb-30 pt-10">
                  {/* <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                    About Hob Services
                  </h5> */}
                  <h2 className="tp-section-title">Gilma Hob Service </h2>
                </div>
                <p className="mb-30 pt-10">
                  Classic Services is dedicated to providing excellent service
                  for Gilma Hobs. We offer comprehensive services, including
                  Gilma hob servicing, repairs, and maintenance. Whether you
                  need routine servicing to keep your Gilma hob performing
                  optimally, expert repairs to address any issues, or thorough
                  care to ensure it operates efficiently, our experienced
                  technicians are here to assist you. We take pride in
                  delivering efficient and reliable solutions to keep your Gilma
                  hob in top-notch condition, enabling you to enjoy seamless
                  cooking experiences.
                </p>

                <div className="col-xl-6 col-lg-3 ">
                  <div
                    className="tp-appoint text-end wow fadeInUp"
                    data-wow-delay=".9s"
                  >
                    <div class="button-container">
                      <button class="custom-button">
                        <a href="tel:+91 6362503661">
                          <i class="icon">
                            <FaPhoneAlt />
                          </i>
                          Call Us
                        </a>
                      </button>
                      <button class="custom-button2">
                        <a href="https://wa.link/al1chv">
                          <i class="icon1">
                            <FaWhatsapp />
                          </i>
                          WhatsApp Us
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Form />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default GilmaServices;
