import React from 'react';
import Footer from '../../../components/shared/Footer/Footer';
import Navigation from '../../../components/shared/Navigation/Navigation';
import AboutArea from './AboutArea/AboutArea';
import BannerArea from './BannerArea/BannerArea';
import Blogs from './Blogs/Blogs';
import ChoseArea from './ChoseArea/ChoseArea';
import CtaArea from './CtaArea/CtaArea';
import FactArea from './FactArea/FactArea';
import HeroSection from './HeroSection/HeroSection';
import PricingArea from './PricingArea/PricingArea';
import ProgressArea from './ProgressArea/ProgressArea';
import Projects from './Projects/Projects';
import ServiceArea from './ServiceArea/ServiceArea';
import Testimonial from './Testimonial/Testimonial';
import ServicesArea from './../../Services/Services/ServicesArea/ServicesArea';

const Home = () => {
   return (
      <>
            <Navigation/> 
            <HeroSection />
            <CtaArea />
            <AboutArea />
            {/* <BannerArea /> */}
            <ServicesArea />
            <div class="elfsight-app-3b310340-1555-4759-9ede-eaa4835b3c97 pt-50 pb-100"></div>
            <Footer/>
   
      </>
   );
};

export default Home;