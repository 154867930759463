import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

const Form = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_kicl8yc",
        "template_aphe1ki",
        form.current,
        "oIlBKT1UMfyW1vJKj"
      )
      .then(
        (result) => {
          console.log(result.text);
          window.location.href = window.location.origin + "/Thankyou";
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <div className="col-lg-12 pb-40 ">
          <div className="tp-contact-form">
            <div className="row custom-mar-20">
              <div className="text-center">
                <h2 className="tp-section-title heading-color-black pt-40 mb-20">
                  Contact Us
                </h2>
              </div>
              <div className="col-md-6 custom-pad-20">
                <div className="tp-contact-form-field mb-20">
                  <input type="text" placeholder="Full name" name="from_name" />
                </div>
              </div>
              <div className="col-md-6 custom-pad-20">
                <div className="tp-contact-form-field mb-20">
                  <input
                    type="email"
                    placeholder="Email Address"
                    name="from_email"
                  />
                </div>
              </div>
              <div className="col-md-6 custom-pad-20">
                <div className="tp-contact-form-field mb-20">
                  <input type="tel" placeholder="Phone" name="Phone" />
                </div>
              </div>
              <div className="col-md-6 custom-pad-20">
                <div className="tp-contact-form-field select-field-arrow mb-20">
                  <select name="service">
                    <option defaultValue="">Select Services</option>
                    <option value="Chimney Services">Chimney Services</option>
                    <option value="Hob Services">Hob Services</option>
                    <option value="Oven Services">Oven Services</option>
                    <option value="Faber Services">Faber Services</option>
                    <option value="Elica Services">Elica Services</option>
                    <option value="Gilma Services">Gilma Services</option>
                    <option value="Kaff Services">Kaff Services</option>
                    <option value="Cooking Range Services">
                      Cooking Range Services
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-md-12 custom-pad-20">
                <div className="tp-contact-form-field mb-20">
                  <textarea
                    placeholder="Your Message"
                    name="message"
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 custom-pad-20">
                <div className="tp-contact-form-field">
                  <button
                    input
                    type="submit"
                    value="Send"
                    className="theme-btn text-white"
                  >
                    <i className="flaticon-enter"></i> Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Form;
