import React from "react";
import { Fade } from "react-reveal";
import { FaPhoneAlt } from "react-icons/fa";

const HomeHeroSingleSlide = ({ setOpen }) => {
  return (
    <>
      <div
        className="tp-single-slider-three tp-slider-height-three d-flex align-items-center swiper-slide"
        data-swiper-autoplay="400"
      >
        <div className="slide-bg slide_bg_three"></div>
        <div className="slider-img-three">
          <img
            src="assets/img/slider/yellow banner.png"
            className="img-fluid"
            alt="img not found"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-slider-three z-index">
                <Fade left>
                  {/* <h6 className="tp-slider-three-subtitle" ><img src="assets/img/icon/slider-icon-1.png" className="img-fluid" alt="img not found" /> Fast and efficient service</h6> */}
                  <h5 className="tp-slider-three-subtitle section__sm__title common-yellow-shape w-80 mb-35">
                    We Care To Fix It
                  </h5>
                </Fade>

                <Fade right>
                  <h1 className="tp-slider-three-title">
                    Classic
                    <br />
                    Services
                  </h1>
                </Fade>

                <div className="col-xl-2 col-lg-3 ">
                  <div
                    className="tp-appoint text-end wow fadeInUp"
                    data-wow-delay=".9s"
                  >
                    <div class="button">
                    <a href="tel:+91 6362503661">
                            <button class="custom-button">
                                    <i class="icon">
                                        <FaPhoneAlt />
                                    </i>
                                    Call Us
                            </button>
                            </a>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeHeroSingleSlide;
