import React from 'react';

const ContactMap = () => {
   return (
      <>
         <div className="tp-map-area pt-120 pb-115">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="tp-contact-map">
                               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4655.8856914887265!2d77.54848301740316!3d13.03878053703591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d5cb27dbaa3%3A0x968ecb7506a4c646!2sClassic%20services%20-%20Chimney%20hob!5e0!3m2!1sen!2sin!4v1693833155172!5m2!1sen!2sin" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>

   );
};

export default ContactMap;