import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

const CookingServicesBreadcrumb = () => {
    return (
        <>
            <Breadcrumb title="Cooking Range" subTitle="Service" smText='Service'></Breadcrumb>
        </>
    );
};

export default CookingServicesBreadcrumb;