import React from "react";
import "./Thankyou.css";
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";

const Thankyou = () => {
  return (
    <>
      <div className="thank-you-container">
        <div className="thank-you-message">
          <h1 className="heading">Form Submitted Successfully</h1>
        </div>
        <div className="get-back-message">
          <p>We appreciate your submission and will get back to you shortly.</p>
        </div>
        <div className="col-xl-7 col-lg-3 ">
          <div
            className="tp-appoint text-end wow fadeInUp"
            data-wow-delay=".9s"
          >
            <div class="button-container ">
              <button class="custom-button">
                <a href="tel:+91 6362503661">
                  <i class="icon">
                    <FaPhoneAlt />
                  </i>
                  Call Us
                </a>
              </button>
              <button class="custom-button1">
                <a href="https://wa.link/al1chv">
                  <i class="icon1">
                    <FaWhatsapp />
                  </i>
                  WhatsApp Us
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thankyou;
