import React from 'react';
import CBreadcrumb from '../../../../components/Breadcrumb/CBreadcrumb';

const ContactBreadcrumb = () => {
   return (
      <>
         <CBreadcrumb title="Contact" subTitle="Us" smText='Contact'></CBreadcrumb>
      </>
   );
};

export default ContactBreadcrumb;