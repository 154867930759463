import React from "react";
import ServicePageArea from "../../../../components/ServiceArea/ServicePageArea";
import ServicePageArea1 from "../../../../components/ServiceArea/ServicePageArea1";
import ServicePageArea2 from "../../../../components/ServiceArea/ServicePageArea2";
import ServicePageArea3 from "../../../../components/ServiceArea/ServicePageArea3";

const ServicesArea = () => {
  return (
    <>
      <section className="tp-service-area-three pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="section-title-wrapper text-center mb-55 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black">
                  Our Best Services
                </h5>
                <h2 className="tp-section-title heading-color-black">
                  Quality Cleaning Making <br />
                  you Much Happy
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <ServicePageArea2
              service_image_num="11"
              ser_icon_img="Chimney Services"
              ser_title="Chimney Services"
            ></ServicePageArea2>

            <ServicePageArea
              service_image_num="9"
              ser_icon_img="Hob Services"
              ser_title="Hob Services"
            ></ServicePageArea>

            <ServicePageArea1
              service_image_num="10"
              ser_icon_img="Oven Services"
              ser_title="Oven Services"
            ></ServicePageArea1>

            <ServicePageArea3
              service_image_num="12"
              ser_icon_img="Cooking Range Services"
              ser_title="Cooking Range Services"
            ></ServicePageArea3>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesArea;
