import React from "react";
import { Link } from "react-router-dom";
import { FaPaperPlane } from "react-icons/fa";
import {
  FaMapMarkerAlt,
  FaEnvelopeOpen,
  FaFacebookF,
  FaPhone,
} from "react-icons/fa";
import { AiOutlineGoogle } from "react-icons/ai";
import { BsTwitter, BsInstagram } from "react-icons/bs";

const Footer = () => {
  return (
    <>
      <footer className="theme-dark-bg">
        <div className="tp-footer-area-two pt-80 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div
                  className="tp-footer-widget footer-col-1 mb-30 wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <div className="tp-footer-info">
                    <div className="tp-footer-info-logo mb-35">
                      <Link to="/">
                        <img
                          src="assets/img/logo/classic logo.png"
                          width="170px"
                          height="170px"
                          className="img-fluid"
                          alt="img not found"
                        />
                      </Link>
                    </div>
                    <div className="tp-footer-info-social">
                      <a href="/">
                        <i>
                          <FaFacebookF className="icon" />{" "}
                        </i>
                      </a>
                      <a href="/">
                        <i>
                          <BsTwitter className="icon" />{" "}
                        </i>
                      </a>
                      <a href="/">
                        <i>
                          <BsInstagram className="icon" />{" "}
                        </i>
                      </a>
                      <a href="/">
                        <i>
                          <AiOutlineGoogle className="icon" />{" "}
                        </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div
                  className="tp-footer-widget footer-col-2 mb-30 wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="tp-footer-widget-title mb-35">Quick Links</h4>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/about">About</a>
                    </li>
                    <li>
                      <a href="/services">Services</a>
                    </li>
                    <li>
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div
                  className="tp-footer-widget footer-col-2 mb-30 wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="tp-footer-widget-title mb-35">Our Services</h4>
                  <ul>
                    <li>
                      <a href="/ChimneyServices">Chimney Services</a>
                    </li>
                    <li>
                      <a href="/HobServices">Hob Services</a>
                    </li>
                    <li>
                      <a href="/OvenServices">Oven Services</a>
                    </li>
                    <li>
                      <a href="/CookingRangeServices">Cooking Range Services</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div
                  className="tp-footer-widget footer-col-4 mb-30 wow fadeInUp"
                  data-wow-delay="1.2s"
                >
                  <h4 className="tp-footer-widget-title mb-40">Contact Us</h4>
                  {/* <div className="tp-footer-insta">
                              <a href="/">
                                 <img src="assets/img/footer/footer-insta-1.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="/">
                                 <img src="assets/img/footer/footer-insta-2.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="/">
                                 <img src="assets/img/footer/footer-insta-3.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="/">
                                 <img src="assets/img/footer/footer-insta-4.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="/">
                                 <img src="assets/img/footer/footer-insta-5.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="/">
                                 <img src="assets/img/footer/footer-insta-6.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                           </div> */}
                  <div className="tp-footer-info">
                    <h6 className="mb-15">
                      <i>
                        {" "}
                        <FaPhone />{" "}
                      </i>
                      <a href="tel:+91 6362503661">+91 6362503661</a>
                    </h6>
                    <h6 className="mb-15">
                      {" "}
                      <i>
                        {" "}
                        <FaEnvelopeOpen />{" "}
                      </i>
                      <a href="mailto:classicserviceIndia@gmail.com">
                        classicserviceIndia@gmail.com
                      </a>
                    </h6>
                    <h6 className="mb-20">
                      {" "}
                      <i>
                        {" "}
                        <FaMapMarkerAlt />{" "}
                      </i>{" "}
                      Classic Services No1, 7th C Main Rd, Bandappa Garden,
                      Mathikere, Bengaluru, Karnataka 560054
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tp-copyright-area-two bg-green-light z-index pt-30 pb-30">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="tp-copyright tp-copyright-two text-center">
                  <p className="m-0">
                    Copyright ©2023 <span>Classic Repairs</span>. All Rights
                    Reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
