import React from "react";
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";
import Footer from "../../../components/shared/Footer/Footer";
import Navigation from "../../../components/shared/Navigation/Navigation";
import ChimneyServicesBreadcrumb from "./ServicesBreadcrumb/ChimneyServicesBreadcrumb";
import Form from "./../Services/Form";

const ChimneyServices = () => {
  return (
    <>
      <Navigation />
      <ChimneyServicesBreadcrumb />
      <section className="tp-about-area position-relative pt-120 pb-60 fix">
        <div className="container">
          <div className="row justify-content-xl-between justify-content-md-center">
            <div className="col-xl-6">
              <div className="tp-service-details-img mb-30">
                <img
                  src="assets/img/service/chimney.png"
                  alt="img not found"
                  height="400px"
                />
              </div>
            </div>
            {/* </div> */}
            <div className="col-xl-6 col-md-10 pl-30">
              <div
                className="tp-about-text z-index wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="section-title-wrapper mb-30 pt-10">
                  {/* <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                    About Hob Services
                  </h5> */}
                  <h2 className="tp-section-title">About Chimney Service </h2>
                </div>
                <p className="mb-30 pt-10">
                  Kitchen chimney services not only improve the efficiency of
                  the chimney in removing smoke and odors but also extend its
                  lifespan and maintain the air quality in your kitchen. Regular
                  maintenance helps prevent fire hazards and ensures that your
                  kitchen remains a comfortable and safe cooking space. If
                  you're seeking professional kitchen chimney services, consider
                  reaching out to Classic Services.
                </p>
                <div className="col-xl-6 col-lg-3 ">
                  <div
                    className="tp-appoint text-end wow fadeInUp"
                    data-wow-delay=".9s"
                  >
                    <div class="button-container">
                      <button class="custom-button">
                        <a href="tel:+91 6362503661">
                          <i class="icon">
                            <FaPhoneAlt />
                          </i>
                          Call Us
                        </a>
                      </button>
                      <button class="custom-button2">
                        <a href="https://wa.link/al1chv">
                          <i class="icon1">
                            <FaWhatsapp />
                          </i>
                          WhatsApp Us
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="tp-service-details">
            <div className="text-center">
              <h2 className="tp-section-title heading-color-black pt-40 mb-20">
                Comprehensive Chimney Care Services
              </h2>
            </div>
            <p className="mb-50">
              <b>Your Partner for Cleaner, Safer Homes:</b>
              <br />
              Welcome to Classic Repair, your trusted destination for expert
              chimney service, repair, and cleaning. We understand your
              chimney's pivotal role in maintaining a healthy and comfortable
              home environment. With a commitment to excellence, safety, and
              efficiency, our comprehensive range of services ensures that your
              chimney is always in top-notch condition.
              <br />
              <br />
              <b>Chimney Inspection and Repair:</b>
              <br /> Our skilled technicians are adept at diagnosing and
              resolving chimney issues of all kinds. Whether it's addressing
              cracks, leaks, or structural concerns, we employ advanced
              techniques to restore your chimney's integrity. Our thorough
              inspections help identify potential hazards before they escalate,
              ensuring your family's safety.
              <br />
              <br />
              <b>Chimney Cleaning and Maintenance:</b>
              <br /> Experience improved air quality and efficiency with our
              professional chimney cleaning services. Our meticulous process
              eliminates creosote buildup, debris, and blockages that can
              compromise proper airflow and increase the risk of fires. Regular
              cleaning not only enhances chimney performance but also prolongs
              its lifespan.
              <br />
              <br />
              <b>Flue Liner Repair and Replacement:</b>
              <br /> A damaged or deteriorating flue liner can pose serious
              risks. Our experts specialize in repairing or replacing flue
              liners, ensuring efficient ventilation and preventing harmful
              emissions from entering your living space. Rest easy knowing a
              properly functioning flue system safeguards your home.
              <br />
              <br />
              <b>Smoke Chamber Restoration:</b>
              <br /> The smoke chamber is critical to your chimney's
              functionality. Our skilled artisans are equipped to restore smoke
              chambers to optimal condition, promoting efficient smoke and gas
              ventilation and minimizing the risk of smoke backdrafts.
              <br />
              <br />
              <b>Waterproofing and Weather Protection: </b>
              <br /> Protect your chimney from the elements with our premium
              waterproofing solutions. We apply advanced sealants to shield your
              chimney against moisture damage, cracking, and deterioration,
              ensuring its longevity and durability. At Classic Repair, we pride
              ourselves on delivering personalized solutions tailored to your
              chimney's needs. Our trained professionals combine expertise,
              cutting-edge technology, and a commitment to customer satisfaction
              to provide you with a safer, cleaner, and more efficient chimney
              system. Trust us to be your dedicated partner in chimney care,
              keeping your home warm, inviting, and secure.
            </p>
            <Form />
            <div className="row">
              <div className="col-sm-6">
                <div className="tp-service-details-img mb-10">
                  <img
                    src="assets/img/service/chimney1.png"
                    alt="img not found"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="tp-service-details-img mb-10">
                  <img
                    src="assets/img/service/chimney2.png"
                    alt="img not found"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ChimneyServices;
